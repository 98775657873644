import React from 'react'
import james from '../images/james.png'

function BlogFooter() {
  return (
    <div style={{display: 'flex'}}>
      <div
        style={{
          paddingRight: 20,
        }}
      >
        <img
          src={james}
          alt="James"
          style={{
            maxWidth: 80,
            borderRadius: '50%',
          }}
        />
      </div>
      <p>
        <strong>James</strong>
        {` is a software engineer and a lifelong learner`}
      </p>
    </div>
  )
}

export default BlogFooter
